export const errors = {
  required: 'This field is required',
  email: 'Please enter valid email address',
  dateOfBirth: 'Please enter valid date of birth',
  atLeastOne: 'At least one field has to be provided',
  invalidEmailOrPhone: 'Field must be valid email or phone number',
  minlength: 'The no. of characters should not be less than',
  maxlength: 'The no. of characters should be more than',
  notValid: 'Field is not valid',
};
