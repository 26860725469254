export const errors = {
  required: 'Este campo es obligatorio',
  email: 'Por favor, ingresa una dirección de correo electrónico válida',
  dateOfBirth: 'Por favor, ingresa una fecha de nacimiento válida',
  atLeastOne: 'Debe proporcionarse al menos un campo',
  invalidEmailOrPhone: 'El campo debe ser un correo electrónico o número de teléfono válido',
  minlength: 'El número de caracteres no debe ser menor que',
  maxlength: 'El número de caracteres no debe ser mayor que',
  notValid: 'El campo no es válido',
};
